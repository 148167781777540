import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout.js"

const MusicAndLife = ({ data }) => {
    return(
        <Layout pageTitle="Life and Music">
            <div className="row">
                <div className="col-12 mb-3">
                    <p>Posts about my experiences and playlists of some of my favorite artists and songs.</p>
                </div>
            </div>
            {data.articles.nodes.map(( node ) => (
                <article className="row teaser" key={node.id}>
                    <h2 className="page-link--slug m-0">
                        <Link to={`/life-and-music/${node.slug}`}>
                            {node.frontmatter.title}
                        </Link>
                    </h2>
                    <p className="mt-2">Posted: {node.frontmatter.date}</p>
                </article>
            ))}
        </Layout>
    )
}

export default MusicAndLife

export const query = graphql`
    query {
        articles: allMdx(
            sort: {fields: frontmatter___date, order: DESC}
        ) {
            nodes {
                frontmatter {
                    date(formatString: "MMMM D, YYYY")
                    title
                }
                id
                slug
            }
        }
    }
`